import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Item, Order, OrderItem, Session, Table, User, Waiter } from 'service';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import ArthaGateway from 'service/ArthaGateway';
import toast from 'react-hot-toast';

export const PageTable = () => {
    const userData = useSelector((state: RootState) => state.appdata);
    const [session, setSession] = useState<Session>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { id } = useParams();
    const [table, setTable] = useState<Table>();
    const [order, setOrder] = useState<Order>();
    const [waiters, setWaiters] = useState<Waiter[]>([]);
    const [items, setItems] = useState<Item[]>([]);
    const [screenName, setScreenName] = useState<string>("summary");
    const [itemName, setItemName] = useState<string>("");
    const navigate = useNavigate();

    const handleFocus = (event) => event.target.select();

    useEffect(() => {
        if (userData.profile) {
            setSession(userData.profile.profile);
        }
    }, [userData]);

    useEffect(() => {
        if (order) {
            order.amount = order.items.reduce((a, b) => a + (b.unitPrice * b.quantity), 0);
            setOrder({ ...order });
        }
    }, [order?.items]);

    useEffect(() => {
        if (session != null) {
            loadWaiters();
        }
    }, [session]);

    useEffect(() => {
        if (items != null) {
            let data = localStorage.getItem("tables");
            if (data) {
                let tables = JSON.parse(data);
                if (tables && id) {
                    let table = tables.find((t: Table) => t.id == parseInt(id));
                    if (table) {
                        setTable(table);
                    }
                }
            }
        }
    }, [items]);

    const loadOrderData = async () => {
        const orderData = await ArthaGateway.getOrderDetails(id);
        if (orderData) {
            setOrder(orderData);
            if (orderData.waiterId == 0) {
                setScreenName("waiters");
            }
            setIsLoading(false);
        }
    }

    const loadWaiters = async () => {
        let data = localStorage.getItem("waiters");
        if (data) {
            setWaiters(JSON.parse(data));
            loadItems();
        } else {
            const waiters = await ArthaGateway.getWaiters();
            if (waiters) {
                setWaiters(waiters);
                localStorage.setItem("waiters", JSON.stringify(waiters));
                loadItems();
            }
        }
    }

    const loadItems = async () => {
        let data = localStorage.getItem("items");
        if (data) {
            setItems(JSON.parse(data));
            loadOrderData();
        } else {
            const items = await ArthaGateway.getItems();
            if (items) {
                setItems(items);
                localStorage.setItem("items", JSON.stringify(items));
                loadOrderData();
            }
        }
    }

    const updateQuantity = (e, index) => {
        let quantity = e.target.value;
        if (e.target.value.length === 0) {
            quantity = 0;
        }
        let item = order?.items[index];
        if (order && item) {
            item.quantity = parseInt(quantity);
            setCalculatedValues(item, order);
            setOrder({ ...order, items: [...order.items] });
        }
    }

    const setCalculatedValues = (item: OrderItem, order: Order) => {
        item.price = item.quantity * item.unitPrice;
        order.amount = order.items.reduce((a, b) => a + (b.unitPrice * b.quantity), 0);
    }

    const addItem = (item) => {
        if (order) {
            let existingItem = order.items.find((i) => i.itemId == item.id);
            if (existingItem) {
                existingItem.quantity += 1;
                setCalculatedValues(existingItem, order);
                setOrder({ ...order, items: [...order.items] });
            } else {
                let newItem: OrderItem = {
                    id: 0,
                    itemId: item.id,
                    itemGroupId: item.groupId,
                    orderId: order.id,
                    tableId: order.tableId,
                    itemName: item.name,
                    quantity: 1,
                    kotQuantity: 0,
                    shiftQuantity: 0,
                    unitPrice: item.price,
                    taxPercentage: item.taxPercentage,
                    taxableAmount: item.taxableAmount,
                    cgst: item.cgstTax,
                    sgst: item.cgstTax,
                    price: item.price
                }
                order.items.push(newItem);
                setOrder({ ...order, items: [...order.items] });
            }
            setScreenName("summary");
        }
    }

    const saveKOT = async () => {
        if (order && order.items.length > 0 && table) {
            setIsLoading(true);
            order.tableId = table.id;
            order.tableName = table.name;
            if (order.waiterId == 0) {
                order.waiterId = table.waiterId;
                order.waiterName = table.waiter;
            }
            ArthaGateway.saveKOT(order).then((response) => {
                toast.success("KOT saved successfully.");
                setTimeout(() => {
                    navigate(`/`);
                }, 2000);
            }).catch((error) => {
                toast.error("Error while saving the KOT");
                console.error("Error in saveKOT ->" + error);
                setIsLoading(false);
            })
        }
    }

    const saveBill = async () => {
        if (order && order.items.length > 0 && table) {
            setIsLoading(true);
            order.tableId = table.id;
            order.tableName = table.name;
            if (order.waiterId == 0) {
                order.waiterId = table.waiterId;
                order.waiterName = table.waiter;
            }
            ArthaGateway.saveBill(order).then((response) => {
                toast.success("Bill saved successfully.");
                setTimeout(() => {
                    navigate(`/`);
                }, 2000);
            }).catch((error) => {
                toast.error("Error while saving the Bill");
                console.error("Error in saveKOT ->" + error);
                setIsLoading(false);
            })
        }
    }

    const selectWaiter = (waiter) => {
        if (order) {
            order.waiterId = waiter.id;
            order.waiterName = waiter.name;
            setOrder({ ...order });
            setScreenName("summary");

            if (table) {
                table.waiterId = waiter.id;
                table.waiter = waiter.name;
                setTable({ ...table });
            }
        }
    }

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    return (
        <>
            <div className="page-table">
                {isLoading &&
                    <Loader />
                }
                {!isLoading &&
                    <>
                        <div className='page-header bg-dark text-center mb-4 p-2'>
                            <span className='table-name'>Table {table?.id}</span><br />
                            Waiter: {table?.waiter}
                        </div>
                        {screenName == "summary" &&
                            <>
                                <div className="row" style={{ overflowY: "auto", height: "calc(100vh - 260px)" }}>
                                    <div className="col-12">
                                        {order && order.items.length > 0 &&
                                            <>
                                                <ul className="list-group list-group-sm cart-items">
                                                    {order?.items.map((item, index) => (
                                                        <li className='list-group-item '>
                                                            <div className='row'>
                                                                <div className="col-12 product-name">
                                                                    <b>{item.itemName}</b>
                                                                    {(item.quantity > item.kotQuantity) &&
                                                                        <span className="badge bg-success badge-sm" style={{ marginLeft: "5px" }}>KOT: {(item.quantity - item.kotQuantity)}</span>
                                                                    }
                                                                    {(item.quantity < item.kotQuantity) &&
                                                                        <span className="badge bg-danger badge-sm" style={{ marginLeft: "5px" }}>Cancel: {(item.kotQuantity - item.quantity)}</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-8">
                                                                    <select key={"q_" + index}
                                                                        className='item-quantity'
                                                                        value={item.quantity}
                                                                        onChange={(e) => updateQuantity(e, index)}>
                                                                        <option value={0}>0</option>
                                                                        {Array.from(Array(50).keys()).map((i) => (
                                                                            <option key={i + 1} value={i + 1}>{i + 1}</option>
                                                                        ))}
                                                                    </select>
                                                                    &nbsp; *  &nbsp;
                                                                    <b>{item.unitPrice}</b>
                                                                </div>
                                                                <div className="col-4" style={{ textAlign: 'right' }}>
                                                                    {currencyFormat(item.price)}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                    <li>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="w-100" style={{ textAlign: 'center', fontWeight: 'bolder', backgroundColor: "#000", color: "#fff", fontSize: "2rem" }}>
                                                                    {currencyFormat(order?.amount)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </>
                                        }
                                        {order && order.items.length == 0 &&
                                            <div className="alert alert-info" role="alert">
                                                No order yet received for this table.
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-12 btn-group'>
                                        <NavLink to="/" className="btn btn-danger btn-sm">Back</NavLink>
                                        <div className="btn btn-dark btn-sm" onClick={() => setScreenName("items")}>Add Items</div>
                                    </div>
                                </div>
                                {order && order.items.length > 0 &&
                                    <div className='row mt-2'>
                                        <div className="col-12">
                                            {order.items.filter((i) => i.quantity != i.kotQuantity).length > 0 &&
                                                <div className="btn btn-primary btn-lg dark w-100" onClick={() => saveKOT()}>Print KOT</div>
                                            }
                                            {order.items.filter((i) => i.quantity != i.kotQuantity).length == 0 &&
                                                <div className="btn btn-success btn-lg dark w-100" onClick={() => saveBill()}>Print Bill</div>
                                            }
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        {screenName == "items" &&
                            <>
                                <div className='row'>
                                    <div className='col-12'>
                                        <input type="text" className="form-control" placeholder="Search item" value={itemName} onChange={(e) => setItemName(e.target.value)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="btn btn-dark btn-sm w-100 mb-1" onClick={() => setScreenName("summary")}>Back</div>
                                    </div>
                                </div>
                                <div className='row items-grid'>
                                    {items && items.map((item, index) => (
                                        <>
                                            {(itemName.length == 0 || item.name.toLowerCase().includes(itemName.toLowerCase())) &&
                                                <div key={index} className="col-6 col-md-4 col-lg-3 mb-4">
                                                    <div className="card" onClick={() => addItem(item)}>
                                                        <div className="card-body">
                                                            <span className="card-title">{item.name}</span>
                                                        </div>
                                                        <div className="card-footer">
                                                            <small>{currencyFormat(item.price)}/-</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    ))}
                                </div>
                            </>
                        }
                        {screenName == "waiters" &&
                            <div className='row waiters-grid'>
                                {waiters && waiters.map((waiter, index) => (
                                    <div key={index} className="col-12 col-md-4 col-lg-3 mb-2">
                                        <div className="card" onClick={() => selectWaiter(waiter)}>
                                            <div className="card-body">
                                                <span className="card-title">{waiter.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </>
                }
            </div>
        </>
    );
};
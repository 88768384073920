import axios from 'axios';
import { app_version } from './Utils';

export const serverUrl = `${process.env.REACT_APP_SERVER_URL || ''}`;

axios.defaults.headers.post['Content-Type'] ='application/json';

const ArthaGateway = {
    validateLogin: async function(username, password) {
        try {
            const payload = { name: username, password: password };
            let response = await axios.post(serverUrl + '/user/validate', payload);
            if (response && response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("Invalid login credentials");
            }
        } catch (error) {
            console.error("Error in ArthaGateway::validateLogin ->"+ error);
            throw error;
        }
        return false;
    },
    getTables: async function() {
        try {
            let response = await axios.get(serverUrl + '/table');
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTables ->"+ error);
            throw error;
        }
        return undefined;
    },
    getWaiters: async function() {
        try {
            let response = await axios.get(serverUrl + '/waiter');
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTables ->"+ error);
            throw error;
        }
        return undefined;
    },
    getItems: async function() {
        try {
            let response = await axios.get(serverUrl + '/item');
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTables ->"+ error);
            throw error;
        }
        return undefined;
    },
    getOrderDetails: async function(tableId) {
        try {
            let response = await axios.get(serverUrl + '/order/', { params: { tableId: tableId } });
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getOrderDetails ->"+ error);
            throw error;
        }
        return undefined;
    },
    saveKOT: async function(order) {
        try {
            let response = await axios.post(serverUrl + '/order/kot', order);
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::saveKOT ->"+ error);
            throw error;
        }
        return undefined;
    },
    saveBill: async function(order) {
        try {
            let response = await axios.post(serverUrl + '/order/bill', order);
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::saveBill ->"+ error);
            throw error;
        }
        return undefined;
    },
    logActivity: async function(activity) {
        try {
            let logActivity = { ...activity, appVersion: app_version }
            let response = await axios.post(serverUrl + '/master/dealer/app/activity', logActivity);
            if (response) {  
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::logActivity ->"+ error);
            throw error;
        }
        return undefined;
    }
}

export default ArthaGateway;
import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Item, Session, Table, User, Waiter } from 'service';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import PaymentRequestImage from '../../assets/payment_request.png'
import BankBalanceImage from '../../assets/balance.png'
import { app_version, clearCache } from 'service/Utils';
import ArthaGateway from 'service/ArthaGateway';

export const Dashboard = () => {
    const userData = useSelector((state: RootState) => state.appdata);
    const [session, setSession] = useState<Session>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [tables, setTables] = useState<Table[]>([]);
    const [waiters, setWaiters] = useState<Waiter[]>([]);
    const [items, setItems] = useState<Item[]>([]);

    useEffect(() => {
        if (userData.profile) {
            setSession(userData.profile.profile);
        }
    }, [userData]);

    useEffect(() => {
        if (session != null) {
            loadTables();
        }
    }, [session]);

    const loadTables = async () => {   
        const tables = await ArthaGateway.getTables();
        if (tables) {
            setTables(tables);
            localStorage.setItem("tables", JSON.stringify(tables));
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="page-dashboard">
                {isLoading &&
                    <Loader />
                }
                {!isLoading &&
                    <>
                        <div className='row'>
                            {tables && tables.map((table, index) => (
                                <div key={index} className="col-6 col-md-4 col-lg-3 mb-4">
                                    <NavLink to={`/table/${table.id}`} className={ table.isOccupied ? 'card occupied' : 'card' }>
                                        <div className="card-body">
                                            <span className="card-title">{table.name}</span>
                                        </div>
                                        <div className="card-footer">
                                            <small>{table.waiter}</small>
                                        </div>
                                    </NavLink>
                                </div>
                            ))}
                        </div>
                        <div className='row'>
                            <div className="col-12 mb-4">
                                <div style={{ position: "relative", height: 50 }}>
                                    <div className='version-number'>
                                        <div className="btn btn-sm bg-dark" style={{ marginBottom: 10, color: "#fff", float: "left" }} onClick={clearCache}>Update to latest version</div>
                                        <span style={{ float: "right" }} >{app_version}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
};
export const app_version = "v 1.0.0.2";
export const companyName = `${process.env.REACT_APP_COMPANY_NAME || ''}`;
export const durations = [
    { "value": "2024", "label": "FY 2024-2025" },
    { "value": "2023", "label": "FY 2023-2024" },
    { "value": "2022", "label": "FY 2022-2023" },
    { "value": "2021", "label": "FY 2021-2022" },
    { "value": "2020", "label": "FY 2020-2021" },
    { "value": "2019", "label": "FY 2019-2020" },
    { "value": "2018", "label": "FY 2018-2019" },
    { "value": "0"   , "label": "Today" },
    { "value": "1"   , "label": "Yesterday" },
    { "value": "2"   , "label": "This Week" },
    { "value": "3"   , "label": "Last Week" },
    { "value": "4"   , "label": "This Month" },
    { "value": "5"   , "label": "Last Month" },
    { "value": "6"   , "label": "Last 2 Month" },
    { "value": "7"   , "label": "Last 3 Month" },
    { "value": "8"   , "label": "Last Year" },
    { "value": "9"   , "label": "Last 1 Year" }
]

export const clearCache = () => {
    caches.keys().then(function(names) {
        for (let name of names)
            caches.delete(name);
    });
    setTimeout(() => window.location.reload(), 5000);
}
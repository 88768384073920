import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import { Dashboard, Login, PageTable } from './Pages';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../../node_modules/bootstrap/dist/js/bootstrap.js';
import '../scss/style.scss'
import 'bootstrap'
import logo from "../assets/logo.png"
import { ActionCreators } from '../store/actionCreators';
import { RootState } from 'store/store';
import { BottomBar } from './Widgets';
import moment from 'moment';
import { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

function App() {
  const user = useSelector((state: RootState) => state.appdata);
  const dispatch = useDispatch();

  const checkLocalStorage = () => {
    let user_data = localStorage.getItem("user");
    if (user_data && user_data.length > 0) {
      let login_time = localStorage.getItem("login_time");
      if (login_time && login_time.length > 0) {
        let last_login_time = moment(login_time);
        if (moment().diff(last_login_time, 'days') <= 60) {
          let user_info = JSON.parse(user_data);
          dispatch(ActionCreators.login(user_info));
        }
      }
    }
  }

  const isAuthenticated = () => {
    if (!user.isUserLoggedIn) {
      checkLocalStorage();
      if (!user.isUserLoggedIn) {
        return false;
      }
    }
    return true;
  }

  const PrivateRoute = ({ children }) => isAuthenticated() ? children : <Login />

  return (
    <div id="app">
      <Router>
        <div>
          <Toaster
            position="top-center"
            reverseOrder={false}
          />
        </div>
        <div className="container">
          <div className="row" style={{ marginTop: 10, marginBottom: 60 }}>
            <Routes>
              <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path="/table/:id" element={<PrivateRoute><PageTable /></PrivateRoute>} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </div>
        </div>
        {user.isUserLoggedIn &&
          <BottomBar />
        }
      </Router>
    </div>
  );
}

export default App;

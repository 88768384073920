import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressBook, faBank, faBook, faCartShopping, faFileInvoice, faHome, faRightFromBracket, faTruckArrowRight, faUser } from '@fortawesome/free-solid-svg-icons'
import { ActionCreators } from 'store/actionCreators';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';


export const BottomBar = () => {
    const dispatch = useDispatch();

    const confirmLogout = () => {
        swal({
            title: "Confirm logout",
            text: "Are you sure you want to logout?",
            icon: "warning",
            dangerMode: true,
            buttons: {
                default: { text: 'Cancel' },
                cancel: { text: 'Cancel' },
                confirm: { text: 'Logout' }
            }
          })
          .then((confirmLogout) => {
            if (confirmLogout === true) {
                dispatch(ActionCreators.logout());
            }
          });
    }

    return (
        <>
            <nav className="navbar fixed-bottom navbar-light bg-light app-bottom-bar">                
                <li className="nav-item" title="Home">
                    <NavLink to="/">
                        <FontAwesomeIcon icon={faHome} />
                    </NavLink>
                </li>
                <li className="nav-item" title="Logout">
                    <a onClick={confirmLogout} href="#">
                        <FontAwesomeIcon icon={faRightFromBracket} />
                    </a>
                </li>   
            </nav>
        </>
    )
};
